import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../helpers/Api";
import { convertArrayToObj, handleReduxErrorCase } from "../../helpers/Misc";

export const getAllCoursesAsync = createAsyncThunk(
  "courses/getAllCourses",
  //arg is not used
  async (arg, thunkAPI) => {
    try {
      const { data } = await Api.getAllCourses();
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const getEnrolledCourseAsync = createAsyncThunk(
  "courses/getEnrolledCourse",
  //arg is not used
  async (arg, thunkAPI) => {
    try {
      const { data } = await Api.getEnrolledCourse();
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const getEnrollmentForCourseAsync = createAsyncThunk(
  "courses/getEnrollmentForCourse",
  //arg is not used
  async (cid, thunkAPI) => {
    try {
      const { data } = await Api.getEnrollmentForCourse(cid);
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const getCourseAsync = createAsyncThunk(
  "courses/getCourse",
  async ({ cid, failedCb }, thunkAPI) => {
    try {
      const { data } = await Api.getCourse(cid);
      return data;
    } catch (e) {
      if (e.response.status === 404) {
        if (failedCb) failedCb();
      }

      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const addCourseAsync = createAsyncThunk(
  "courses/addCourse",
  async (course, thunkAPI) => {
    try {
      const { data } = await Api.addCourse(course);
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const updateCourseAsync = createAsyncThunk(
  "courses/updateCourse",
  async ({ course, successCb }, thunkAPI) => {
    try {
      await Api.updateCourse(course);

      if (successCb) successCb();
      return course;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const deleteCourseAsync = createAsyncThunk(
  "courses/deleteCourse",
  async ({ cid, successCb }, thunkAPI) => {
    try {
      await Api.deleteCourse(cid);
      if (successCb) successCb();
      return cid;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

//used to enroll current user
export const enrollCourseAsync = createAsyncThunk(
  "courses/enrollCourse",
  async ({ cid, successCb }, thunkAPI) => {
    try {
      await Api.enrollCourse(cid);

      if (successCb) successCb();
      return null;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

//used to unenroll current user
export const unenrollCourseAsync = createAsyncThunk(
  "courses/unenrollCourse",
  async ({ cid, successCb }, thunkAPI) => {
    try {
      await Api.unenrollCourse(cid);

      if (successCb) successCb();
      return null;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const enrollUsersToCourseAsync = createAsyncThunk(
  "courses/enrollUserToCourse",
  async ({ cid, data, progressCb, successCb, failedCb }, thunkAPI) => {
    try {
      const res = await Api.enrollUserToCourseCSV(cid, data, progressCb);
      if (successCb) successCb();
      return res.data;
    } catch (e) {
      if (failedCb) failedCb();

      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const unenrollUsersFromCourseAsync = createAsyncThunk(
  "courses/unenrollUserFromCourse",
  async ({ cid, students }, thunkAPI) => {
    try {
      await Api.unenrollUserFromCourse(cid, students);
      return null;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

const coursesSlice = createSlice({
  name: "courses",
  initialState: { courses: {}, loading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCoursesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCoursesAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.courses = {
            ...state.courses,
            ...convertArrayToObj(action.payload),
          };
        }
        state.loading = false;
      })
      .addCase(getEnrolledCourseAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEnrolledCourseAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.courses = {
            ...state.courses,
            ...convertArrayToObj(action.payload),
          };
        }
        state.loading = false;
      })
      .addCase(getCourseAsync.pending, (state, action) => {
        const { cid } = action.meta.arg;
        if (!state.courses[cid]) {
          state.courses[cid] = { loading: true };
        } else {
          state.courses[cid].loading = true;
        }
      })
      .addCase(getCourseAsync.fulfilled, (state, action) => {
        let course = {};
        if (action.payload) {
          course = action.payload;
          const cid = course._id;
          state.courses[cid] = course;
          state.courses[cid].loading = false;
        }
      })
      .addCase(updateCourseAsync.pending, (state, action) => {
        const { course } = action.meta.arg;
        const cid = course._id;
        if (!state.courses[cid]) {
          state.courses[cid] = { loading: true };
        } else {
          state.courses[cid].loading = true;
        }
      })
      .addCase(updateCourseAsync.fulfilled, (state, action) => {
        const { course } = action.meta.arg;
        const cid = course._id;
        if (action.payload) {
          const oldCourse = state.courses[cid];
          if (oldCourse) {
            const updatedCourse = Object.assign({}, oldCourse, course);
            // cannot do this when using with immer!
            // const updatedCourse = { ...oldCourse, course };
            state.courses[cid] = updatedCourse;
          } else {
            course.students = [];
            state.courses[cid] = course;
          }
        }
        state.courses[cid].loading = false;
      })
      .addCase(updateCourseAsync.rejected, (state, action) => {
        const { course } = action.meta.arg;
        const { _id: cid } = course;
        state.courses[cid].loading = false;
      })
      .addCase(addCourseAsync.pending, (state, action) => {
        state.loading = true;

        const course = action.payload;
        if (action.payload) {
          const { _id: cid } = course;
          state.courses[cid] = course;
        }
      })
      .addCase(addCourseAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const course = action.payload;
          const cid = course._id;
          state.courses[cid] = course;
          state.loading = false;
        }
      })
      .addCase(deleteCourseAsync.fulfilled, (state, action) => {
        const { cid } = action.meta.arg;
        delete state.courses[cid];
      })
      .addCase(unenrollUsersFromCourseAsync.fulfilled, (state, action) => {
        const { cid, students } = action.meta.arg;

        //remove the users from the course
        const course = state.courses[cid];
        const oldStudents = course.students;

        const updatedStudents = [];

        for (const uid of oldStudents) {
          if (!students.includes(uid)) {
            updatedStudents.push(uid);
          }
        }

        const updatedCourse = Object.assign({}, course, {
          students: updatedStudents,
        });
        state.courses[cid] = updatedCourse;
      })
      .addCase(enrollUsersToCourseAsync.fulfilled, (state, action) => {
        const { cid } = action.meta.arg;

        //add the users from the course
        if (action.payload) {
          const students = action.payload;

          const course = state.courses[cid];
          const oldStudents = course.students;

          const updatedStudents = [...oldStudents];

          for (const student of students) {
            const { _id: uid } = student;
            if (!updatedStudents.includes(uid)) {
              updatedStudents.push(uid);
            }
          }

          const updatedCourse = Object.assign({}, course, {
            students: updatedStudents,
          });
          state.courses[cid] = updatedCourse;
        }
      });
  },
});

export default coursesSlice.reducer;
