import React, { useState, useEffect, useRef } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CRow,
} from "@coreui/react";
import { withRouter } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import Api from "../../helpers/Api";
import { useQuery } from "../../helpers/useQuery";
import FormInput from "../FormInput";
import { useForm } from "../../helpers/useForm";

function SetPassword(props) {
  let query = useQuery();
  const hash = query.get("hash");
  const uid = query.get("uid");
  if (!hash || !uid) {
    props.history.push("/resetpassword");
  }

  let [{ password, cpassword }, handleChange] = useForm({
    password: "",
    cpassword: "",
    opassword: "",
  });

  const passwordRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const checkHash = async () => {
    if (!hash || !uid) {
      return;
    }

    try {
      await Api.checkhash(uid, hash);
      setLoading(false);
      passwordRef.current.focus();
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        alert(e.response.data.error);
      } else {
        alert("Unexpected error, please try resetting password again.");
      }
      props.history.push("/resetpassword");
    }
  };

  const checkPassword = () => {
    if (!password) {
      password = "";
    }

    if (!cpassword) {
      cpassword = "";
    }

    if (password !== cpassword) {
      alert("Password do not match");
      return false;
    }
    return true;
  };

  useEffect(() => {
    checkHash();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    if (!checkPassword()) {
      return;
    }

    try {
      await Api.resetpassword(uid, hash, password);

      alert("Password successfully changed");
      props.history.push("/login");
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        alert(e.response.data.error);
      } else {
        alert("Unable to reset password");
      }
    }
  }

  return loading ? (
    <div className="text-center">
      <PulseLoader color={"#482879"} loading={true} />
    </div>
  ) : (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>Specify Password</h1>
                    <p className="text-muted">Enter a new password</p>
                    <FormInput
                      name="password"
                      innerRef={passwordRef}
                      value={password}
                      handleChange={handleChange}
                      placeholder="Enter password"
                      type="password"
                      required={true}
                    />
                    <FormInput
                      name="cpassword"
                      label="Confirm Password"
                      value={cpassword}
                      handleChange={handleChange}
                      placeholder="Enter password again"
                      type="password"
                      required={true}
                    />
                    <CRow>
                      <CCol xs="6">
                        <CButton
                          color="light"
                          onClick={() => {
                            props.history.push("/login");
                          }}
                        >
                          Cancel
                        </CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton type="submit" color="primary" className="px-4">
                          Save
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default withRouter(SetPassword);
