import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "../redux/sidebar/sidebarSlice";
import loginReducer from "../redux/login/loginSlice";
import coursesReducer from "../redux/courses/coursesSlice";
import exercisesReducer from "../redux/exercises/exercisesSlice";
import testsReducer from "../redux/tests/testsSlice";
import usersReducer from "../redux/users/usersSlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    sidebar: sidebarReducer,
    courses: coursesReducer,
    exercises: exercisesReducer,
    tests: testsReducer,
    users: usersReducer,
  },
});
