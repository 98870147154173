//this function will convert an array to

import { logout } from "../redux/login/loginSlice";

//object with the _id being the fields
export function convertArrayToObj(array) {
  const mapping = {};
  for (const obj of array) {
    mapping[obj._id] = obj;
  }

  return mapping;
} //end convertArrayToObj

export function handleReduxErrorCase(e, thunkAPI) {
  if (e.response && e.response.status === 401) {
    if (thunkAPI) {
      thunkAPI.dispatch(logout());
    }
  } else if (e.response && e.response.data && e.response.data.error) {
    alert(e.response.data.error);
  } else {
    alert("Unexpected error");
  }

  if (thunkAPI) {
    return thunkAPI.rejectWithValue(e.message);
  }

  return null;
} //end handleReduxErrorCase
