import React from "react";
import CIcon from "@coreui/icons-react";

const _admin_nav = [
  {
    _tag: "CSidebarNavItem",
    name: "My Submissions",
    to: "/mysubmissions",
    icon: <CIcon name="cil-cloud-upload" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Admin"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Courses",
    to: "/courses",
    icon: <CIcon name="cil-school" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Users",
    to: "/users",
    icon: <CIcon name="cil-people" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Submissions",
    to: "/submissions",
    icon: <CIcon name="cil-cloud-upload" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Reports",
    to: "/reports",
    icon: <CIcon name="cil-graph" customClasses="c-sidebar-nav-icon" />,
  },
];

export default _admin_nav;
