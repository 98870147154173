import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../helpers/Api";
import { handleReduxErrorCase } from "../../helpers/Misc";
import { logout } from "../login/loginSlice";

export const getExercisesForCourseAsync = createAsyncThunk(
  "exercises/getAllExercisesForCourse",
  //arg is not used
  async ({ cid, failedCb }, thunkAPI) => {
    try {
      const { data } = await Api.getExercisesForCourse(cid);
      return data;
    } catch (e) {
      if (e.response && e.response.status === 401) {
        thunkAPI.dispatch(logout());
      } else {
        if (failedCb) {
          failedCb();
        } else {
          alert("Unable to load exercises. Please try refreshing page.");
        }
      }
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const addExerciseAsync = createAsyncThunk(
  "exercises/addExercise",
  async (exercise, thunkAPI) => {
    try {
      const { data } = await Api.addExercise(exercise);
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const deleteExerciseAsync = createAsyncThunk(
  "exercises/deleteExercise",
  async ({ eid }, thunkAPI) => {
    try {
      await Api.deleteExercise(eid);
      return eid;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const updateExerciseAsync = createAsyncThunk(
  "exercises/updateExercise",
  async (exercise, thunkAPI) => {
    try {
      const { data } = await Api.updateExercise(exercise);
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

//switch ordering of 2 exercises
//will only update the order field
export const switchOrderingOfExercisesAsync = createAsyncThunk(
  "exercises/switchOrderingOfExercises",
  async ({ e1, e2 }, thunkAPI) => {
    try {
      const e1Order = e2.order;
      const e2Order = e1.order;

      await Api.updateExercise({ _id: e1._id, order: e1Order });
      await Api.updateExercise({ _id: e2._id, order: e2Order });

      return null;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const uploadFilesToExerciseAsync = createAsyncThunk(
  "exercises/uploadFilesToExercise",
  async ({ eid, cid, files, progress, successCb, failedCb }, thunkAPI) => {
    try {
      await Api.uploadFilesToExercise(eid, files, progress);
      if (successCb) successCb();

      return null;
    } catch (e) {
      if (failedCb) failedCb();

      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const uploadTestscriptsToExerciseAsync = createAsyncThunk(
  "exercises/uploadTestscriptsToExercise",
  async ({ eid, cid, files, progress, successCb, failedCb }, thunkAPI) => {
    try {
      await Api.uploadTestscriptsToExercise(eid, files, progress);
      if (successCb) successCb();

      return null;
    } catch (e) {
      if (failedCb) failedCb();

      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const deleteFilesOfExerciseAsync = createAsyncThunk(
  "exercises/deleteFilesOfExercise",
  async ({ eid, cid, filename }, thunkAPI) => {
    try {
      const { data } = await Api.deleteFilesOfExercise(eid, {
        files: [filename],
      });

      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const deleteTestscriptsOfExerciseAsync = createAsyncThunk(
  "exercises/deleteTestscriptsOfExercise",
  async ({ eid, cid, filename }, thunkAPI) => {
    try {
      const { data } = await Api.deleteTestscriptsOfExercise(eid, {
        files: [filename],
      });

      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

const exercisesSlice = createSlice({
  name: "exercises",
  initialState: { exercises: {}, loading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExercisesForCourseAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExercisesForCourseAsync.fulfilled, (state, action) => {
        const { cid } = action.meta.arg;

        if (action.payload) {
          state.exercises[cid] = action.payload;
        }
        state.loading = false;
      })
      .addCase(addExerciseAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(addExerciseAsync.fulfilled, (state, action) => {
        const exercise = action.payload;
        if (exercise) {
          const { course_id } = exercise;
          const exercises = state.exercises[course_id];
          const updatedExercises = [...exercises, exercise];

          state.exercises[course_id] = updatedExercises;
        }
        state.loading = false;
      })
      .addCase(addExerciseAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateExerciseAsync.fulfilled, (state, action) => {
        const exercise = action.meta.arg;

        if (exercise) {
          const { course_id } = exercise;
          const exercises = state.exercises[course_id];
          const updatedExercises = exercises.map((e) => {
            if (e._id === exercise._id) {
              const updatedExercise = Object.assign({}, e, exercise);
              return updatedExercise;
            } else {
              return e;
            }
          });

          state.exercises[course_id] = updatedExercises;
        }
      })
      .addCase(uploadFilesToExerciseAsync.fulfilled, (state, action) => {
        const { eid, cid, files } = action.meta.arg;

        const exercises = state.exercises[cid];

        const updatedExercises = exercises.map((e) => {
          if (e._id === eid) {
            let updatedFiles = [];

            if (e.files) {
              updatedFiles = [...e.files];
            }

            for (let file of files) {
              file = file[1];
              const { name } = file;
              if (!updatedFiles.includes(name)) {
                updatedFiles.push(name);
              }
            } //end for

            const updatedExercise = Object.assign({}, e, {
              files: updatedFiles,
            });
            return updatedExercise;
          } else {
            return e;
          }
        });

        state.exercises[cid] = updatedExercises;
      })
      .addCase(uploadTestscriptsToExerciseAsync.fulfilled, (state, action) => {
        const { eid, cid, files } = action.meta.arg;

        const exercises = state.exercises[cid];

        const updatedExercises = exercises.map((e) => {
          if (e._id === eid) {
            let updatedTestscripts = [];

            if (e.testscripts) {
              updatedTestscripts = [...e.testscripts];
            }

            for (let file of files) {
              file = file[1];
              const { name } = file;
              if (!updatedTestscripts.includes(name)) {
                updatedTestscripts.push(name);
              }
            } //end for

            const updatedExercise = Object.assign({}, e, {
              testscripts: updatedTestscripts,
            });
            return updatedExercise;
          } else {
            return e;
          }
        });

        state.exercises[cid] = updatedExercises;
      })
      .addCase(switchOrderingOfExercisesAsync.fulfilled, (state, action) => {
        const { e1, e2 } = action.meta.arg;

        const cid = e1.course_id;

        const exercises = state.exercises[cid];

        const updatedExercises = [];

        const e1Order = e2.order;
        const e2Order = e1.order;

        for (let thisExercise of exercises) {
          if (thisExercise._id === e1._id) {
            thisExercise = Object.assign({}, e2, { order: e1Order });
          } else if (thisExercise._id === e2._id) {
            thisExercise = Object.assign({}, e1, { order: e2Order });
          }
          updatedExercises.push(thisExercise);
        } //end for

        state.exercises[cid] = updatedExercises;
      })
      .addCase(deleteFilesOfExerciseAsync.fulfilled, (state, action) => {
        const { eid, cid, filename } = action.meta.arg;

        const exercises = state.exercises[cid];

        const updatedExercises = exercises.map((e) => {
          if (e._id === eid) {
            const updatedFiles = e.files.filter((f) => f !== filename);

            const updatedExercise = Object.assign({}, e, {
              files: updatedFiles,
            });
            return updatedExercise;
          } else {
            return e;
          }
        });

        state.exercises[cid] = updatedExercises;
      })
      .addCase(deleteTestscriptsOfExerciseAsync.fulfilled, (state, action) => {
        const { eid, cid, filename } = action.meta.arg;

        const exercises = state.exercises[cid];

        const updatedExercises = exercises.map((e) => {
          if (e._id === eid) {
            const updatedFiles = e.testscripts.filter((f) => f !== filename);

            const updatedExercise = Object.assign({}, e, {
              testscripts: updatedFiles,
            });
            return updatedExercise;
          } else {
            return e;
          }
        });

        state.exercises[cid] = updatedExercises;
      })
      .addCase(deleteExerciseAsync.fulfilled, (state, action) => {
        const { cid, eid } = action.meta.arg;

        state.exercises[cid] = state.exercises[cid].filter(
          (exercise) => exercise._id !== eid
        );
      });
  },
});

export default exercisesSlice.reducer;
