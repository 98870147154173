import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import AuthService from "../../helpers/AuthService";

function PrivateRoute({
  component: Component,
  authed,
  requireAdmin = false,
  ...rest
}) {
  const { isLoggedIn } = useSelector((state) => state.login);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn) {
          if (requireAdmin) {
            const isAdmin = AuthService.isAdmin();
            if (isAdmin) {
              return <Component {...props} />;
            } else {
              return (
                <Redirect
                  to={{ pathname: "/", state: { from: props.location } }}
                />
              );
            }
          } else {
            return <Component {...props} />;
          }
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
}

export default PrivateRoute;
