import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import useGoogleAnalytics from "../helpers/useGoogleAnalytics";
import { TheContent, TheSidebar, TheFooter, TheHeader } from "./index";

const TheLayout = (props) => {
  useGoogleAnalytics();
  const { isLoggedIn } = useSelector((state) => state.login);

  if (!isLoggedIn) {
    props.history.push("/login");
  }

  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default withRouter(TheLayout);
