import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import { toggle } from "../redux/sidebar/sidebarSlice";

import AuthService from "../helpers/AuthService";
import logo from "../logo_white.png";
import logoSymbol from "../logo_symbol_white.png";

// sidebar nav config
import adminNavigation from "./_admin_nav";
import userNavigation from "./_user_nav";

const TheSidebar = () => {
  const dispatch = useDispatch();
  const { show } = useSelector((state) => state.sidebar);

  const navigationConfig = AuthService.isAdmin()
    ? adminNavigation
    : userNavigation;

  return (
    <CSidebar show={show} onShowChange={(val) => dispatch(toggle(val))}>
      <CSidebarBrand className="d-md-down-none" to="/">
        <CIcon className="c-sidebar-brand-full" src={logo} height={25} />
        <CIcon
          className="c-sidebar-brand-minimized"
          src={logoSymbol}
          height={25}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigationConfig}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
