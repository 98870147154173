import React from "react";
import CIcon from "@coreui/icons-react";

const _user_nav = [
  {
    _tag: "CSidebarNavItem",
    name: "Courses",
    to: "/courses",
    icon: <CIcon name="cil-school" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "My Submissions",
    to: "/mysubmissions",
    icon: <CIcon name="cil-cloud-upload" customClasses="c-sidebar-nav-icon" />,
  },
];

export default _user_nav;
