import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../helpers/Api";
import { handleReduxErrorCase } from "../../helpers/Misc";

export const getTestsForExerciseAsync = createAsyncThunk(
  "tests/getTestsForExercise",
  //arg is not used
  async (eid, thunkAPI) => {
    try {
      const { data } = await Api.getTestsForExercise(eid);
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const addTestAsync = createAsyncThunk(
  "tests/addTest",
  async ({ test, successCb }, thunkAPI) => {
    try {
      const { data } = await Api.addTest(test);
      successCb();
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const deleteTestAsync = createAsyncThunk(
  "tests/deleteTest",
  async ({ tid, eid }, thunkAPI) => {
    try {
      await Api.deleteTest(tid);
      return eid;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const deleteMassTestAsync = createAsyncThunk(
  "tests/deleteMassTest",
  async ({ tids, eid }, thunkAPI) => {
    try {
      for (const tid of tids) {
        await Api.deleteTest(tid);
      }
      return eid;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const updateTestAsync = createAsyncThunk(
  "tests/updateTest",
  async ({ test, successCb }, thunkAPI) => {
    try {
      const { data } = await Api.updateTest(test);
      successCb();
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

const testsSlice = createSlice({
  name: "tests",
  initialState: { tests: {}, loading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTestsForExerciseAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTestsForExerciseAsync.fulfilled, (state, action) => {
        const eid = action.meta.arg;

        if (action.payload) {
          state.tests[eid] = action.payload;
        }
        state.loading = false;
      })
      .addCase(addTestAsync.fulfilled, (state, action) => {
        const test = action.payload;

        if (test) {
          const { exercise_id: eid } = test;
          const tests = state.tests[eid];
          const updatedTests = [...tests, test];
          state.tests[eid] = updatedTests;
        }
      })
      .addCase(updateTestAsync.fulfilled, (state, action) => {
        const { test } = action.meta.arg;

        if (test) {
          const { _id: tid } = test;
          const { exercise_id: eid } = test;
          const tests = state.tests[eid];
          const updatedTests = tests.map((t) => {
            if (t._id === tid) {
              const updatedTest = Object.assign({}, t, test);
              return updatedTest;
            } else {
              return t;
            }
          });
          state.tests[eid] = updatedTests;
        }
      })
      .addCase(deleteTestAsync.fulfilled, (state, action) => {
        const { tid, eid } = action.meta.arg;

        const tests = state.tests[eid];

        const updatedTests = tests.filter((t) => t._id !== tid);
        state.tests[eid] = updatedTests;
      })
      .addCase(deleteMassTestAsync.fulfilled, (state, action) => {
        const { tids, eid } = action.meta.arg;

        for (const tid of tids) {
          const tests = state.tests[eid];

          const updatedTests = tests.filter((t) => t._id !== tid);
          state.tests[eid] = updatedTests;
        } //end for
      });
  },
});

export default testsSlice.reducer;
