import React from "react";

const UserProfile = React.lazy(() => import("./components/UserProfile"));
const Home = React.lazy(() => import("./components/Home"));
const CourseList = React.lazy(() => import("./components/CourseList"));
const CourseDetail = React.lazy(() => import("./components/CourseDetail"));
const UserList = React.lazy(() => import("./components/UserList"));
const SubmissionList = React.lazy(() => import("./components/SubmissionList"));
const MySubmissionList = React.lazy(() =>
  import("./components/MySubmissionList")
);
const ReportList = React.lazy(() => import("./components/ReportList"));

const routes = [
  { path: "/", exact: true, name: "Home", component: Home },
  { path: "/courses/:cid", name: "Course Details", component: CourseDetail },
  { path: "/courses", name: "Courses", component: CourseList },
  { path: "/profile", name: "User Profile", component: UserProfile },
  { path: "/users", name: "Users", component: UserList, adminOnly: true },
  {
    path: "/submissions",
    name: "Submissions",
    component: SubmissionList,
    adminOnly: true,
  },
  { path: "/mysubmissions", name: "Submissions", component: MySubmissionList },
  { path: "/reports", name: "Reports", component: ReportList, adminOnly: true },
];

export default routes;
