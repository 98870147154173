import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CAlert,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import { useForm } from "../../helpers/useForm";
import Api from "../../helpers/Api";
import { withRouter } from "react-router-dom";

function ResetPassword(props) {
  const [error, setError] = useState(null);
  const [{ email }, handleChange] = useForm({
    email: "",
  });

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await Api.requestresetpassword(email);
      setError(null);

      alert(
        "Please check your email for the password reset link. Note that the email might be in the spam folder."
      );
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        setError(e.response.data.error);
      } else {
        setError("Unable to reset password");
      }
    }
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>Reset Password</h1>
                    <p className="text-danger">
                      Note that NUS email server seems to be blocking the email
                      sent from Autograder. Please contact your instructor if
                      you did not receive the reset email.
                    </p>
                    <p className="text-muted">Enter your email</p>
                    {error ? <CAlert color="danger">{error}</CAlert> : ""}
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        autoFocus
                        required
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton
                          color="light"
                          onClick={() => {
                            props.history.push("/login");
                          }}
                        >
                          Cancel
                        </CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton type="submit" color="primary" className="px-4">
                          Send Reset Link
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default withRouter(ResetPassword);
