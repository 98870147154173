import React from "react";
import {
  CCol,
  CFormGroup,
  CLabel,
  CInput,
  CTextarea,
  CSwitch,
  CSelect,
  CRow,
} from "@coreui/react";
import Select from "react-select";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.custom.css";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function FormInput({
  innerRef,
  name,
  label,
  smallLabel,
  placeholder,
  value,
  handleChange,
  handleChangeDate,
  type = "input",
  rows = 3,
  required = false,
  mapping = {},
}) {
  if (!label) {
    label = capitalizeFirstLetter(name);
  }

  return (
    <CFormGroup row>
      <CCol md="3">
        <strong>
          <CLabel htmlFor={name}>{label}</CLabel>
        </strong>
        {smallLabel && (
          <>
            <br />
            <small>({smallLabel})</small>
          </>
        )}
      </CCol>
      <CCol xs="12" md="9">
        {type === "label" && <span>{value}</span>}
        {type === "input" && (
          <CInput
            id={name}
            name={name}
            innerRef={innerRef}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            required={required}
          />
        )}
        {type === "number" && (
          <CInput
            type="number"
            id={name}
            name={name}
            innerRef={innerRef}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            required={required}
          />
        )}
        {type === "email" && (
          <CInput
            type="email"
            autoComplete="email"
            id={name}
            name={name}
            innerRef={innerRef}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            required={required}
          />
        )}
        {type === "password" && (
          <CInput
            type="password"
            id={name}
            name={name}
            innerRef={innerRef}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            required={required}
            autoComplete="new-password"
          />
        )}
        {type === "textarea" && (
          <CTextarea
            id={name}
            name={name}
            innerRef={innerRef}
            value={value}
            rows={rows}
            placeholder={placeholder}
            onChange={handleChange}
            required={required}
          />
        )}
        {type === "switch" && (
          <CSwitch
            id={name}
            name={name}
            className={"mb-0"}
            color={"primary"}
            checked={value}
            variant={"3d"}
            tabIndex="0"
            shape={"pill"}
            labelOn={"\u2713"}
            labelOff={"\u2715"}
            onChange={handleChange}
          />
        )}
        {type === "select" && (
          <CSelect
            custom
            name={name}
            id={name}
            value={value}
            onChange={handleChange}
            required={required}
          >
            {Object.keys(mapping).map((k, index) => (
              <option key={index} value={k}>
                {mapping[k]}
              </option>
            ))}
          </CSelect>
        )}
        {type === "multiselect" && (
          <Select
            isMulti
            options={mapping}
            onChange={handleChange}
            value={value}
            styles={{
              control: (provided, state) => ({
                ...provided,
                outline: 0,
                boxShadow: state.isFocused
                  ? "0 0 0 3px rgba(112, 88, 149, .25)"
                  : "",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#705895",
              },
            })}
          />
        )}
        {type === "datetime" && (
          <CRow>
            <CCol xs="12" md="2">
              <CSwitch
                id={name}
                name={name}
                className={"mb-0"}
                color={"primary"}
                checked={value}
                variant={"3d"}
                tabIndex="0"
                shape={"pill"}
                labelOn={"\u2713"}
                labelOff={"\u2715"}
                onChange={handleChange}
              />
            </CCol>
            <CCol xs="12" md="10">
              {value && (
                <DatePicker
                  custom
                  name={name}
                  id={name}
                  selected={value}
                  onChange={handleChangeDate}
                  showTimeSelect
                  dateFormat="MMMM dd, yyyy h:mm a"
                  className="form-control"
                  minDate={new Date()}
                />
              )}
            </CCol>
          </CRow>
        )}
      </CCol>
    </CFormGroup>
  );
}

export default FormInput;
