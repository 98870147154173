module.exports = {
  // SERVER_HOST: "http://localhost:9105",
  SERVER_HOST: "https://apiautograder.recodefy.com",
  TECHNOLOGY_LIST: {
    "": "Please select",
    r: "R",
    python: "Python",
    javascript: "Javascript",
    react: "React",
    ejb: "EJB",
    war: "WAR",
  },
};
