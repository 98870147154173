import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CAlert,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import { login } from "../../redux/login/loginSlice";

import { useForm } from "../../helpers/useForm";
import Api from "../../helpers/Api";
import { useDispatch } from "react-redux";
import AuthService from "../../helpers/AuthService";
import { Link } from "react-router-dom";

function Login(props) {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [{ email, password }, handleChange] = useForm({
    email: "",
    password: "",
  });

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const { data } = await Api.login(email, password);
      AuthService.login(data);

      setError(null);
      dispatch(login(data));

      if (props.history.location.state && props.history.location.state.from) {
        //go to the last bounce off url if possible
        props.history.push(props.history.location.state.from);
      } else {
        props.history.push("/");
      }
    } catch (error) {
      if (error.response.status === 401) {
        //invalid login
        setError("Login failed. Please try again.");
      }
    }
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    {error ? <CAlert color="danger">{error}</CAlert> : ""}
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        autoFocus
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        name="password"
                        value={password}
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <Link to="/resetpassword">
                          <CButton color="link" className="px-0">
                            Forgot password?
                          </CButton>
                        </Link>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton
                          type="submit"
                          color="primary"
                          className="px-4"
                          disabled={!validateForm()}
                        >
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default Login;
