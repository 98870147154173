import { useState } from "react";

//this custom hook will set the state according to the form element name
//mainly used for textboxes input
//returns [values, handleChange, setValues]
export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  return [
    values,
    (e) => {
      if (e.target.type === "checkbox") {
        setValues({
          ...values,
          [e.target.name]: e.target.checked,
        });
      } else {
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        });
      }
    },
    setValues,
  ];
};
