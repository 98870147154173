import { SERVER_HOST } from "../config";
import axios from "axios";
import AuthService from "./AuthService";

//used to capture the bearer token
function generateAuthHeaders() {
  return {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${AuthService.getAccessToken()}`,
    },
  };
}

const Api = {
  login(email, password) {
    return axios.post(`${SERVER_HOST}/login`, {
      email,
      password,
    });
  },
  requestresetpassword(email) {
    return axios.post(`${SERVER_HOST}/requestresetpassword`, {
      email,
    });
  },
  checkhash(uid, hash) {
    return axios.post(`${SERVER_HOST}/checkhash/${uid}`, {
      hash,
    });
  },
  resetpassword(uid, hash, password) {
    return axios.post(`${SERVER_HOST}/resetpassword/${uid}`, {
      hash,
      password,
    });
  },
  getAllCourses() {
    return axios.get(`${SERVER_HOST}/courses`, generateAuthHeaders());
  },
  getEnrolledCourse() {
    return axios.get(`${SERVER_HOST}/me/courses`, generateAuthHeaders());
  },
  addCourse(course) {
    delete course._id;
    return axios.post(`${SERVER_HOST}/courses`, course, generateAuthHeaders());
  },
  getSubmission(sid) {
    return axios.get(
      `${SERVER_HOST}/submissions/${sid}`,
      generateAuthHeaders()
    );
  },
  getCourse(cid) {
    return axios.get(`${SERVER_HOST}/courses/${cid}`, generateAuthHeaders());
  },
  updateCourse(course) {
    const { _id: cid } = course;
    return axios.patch(
      `${SERVER_HOST}/courses/${cid}`,
      course,
      generateAuthHeaders()
    );
  },
  deleteCourse(cid) {
    return axios.delete(`${SERVER_HOST}/courses/${cid}`, generateAuthHeaders());
  },
  setPublishCourse(cid, published) {
    return axios.patch(
      `${SERVER_HOST}/courses/${cid}`,
      {
        _id: cid,
        published,
      },
      generateAuthHeaders()
    );
  },
  getExercisesForCourse(cid) {
    return axios.get(
      `${SERVER_HOST}/courses/${cid}/exercises`,
      generateAuthHeaders()
    );
  },
  getEnrollmentForCourse(cid) {
    return axios.get(
      `${SERVER_HOST}/courses/${cid}/students`,
      generateAuthHeaders()
    );
  },
  addExercise(exercise) {
    delete exercise._id;
    const cid = exercise.course_id;
    return axios.post(
      `${SERVER_HOST}/courses/${cid}/exercises`,
      exercise,
      generateAuthHeaders()
    );
  },
  getExercise(eid) {
    return axios.get(`${SERVER_HOST}/exercises/${eid}`, generateAuthHeaders());
  },
  deleteExercise(eid) {
    return axios.delete(
      `${SERVER_HOST}/exercises/${eid}`,
      generateAuthHeaders()
    );
  },
  updateExercise(exercise) {
    const { _id: eid } = exercise;
    return axios.patch(
      `${SERVER_HOST}/exercises/${eid}`,
      exercise,
      generateAuthHeaders()
    );
  },
  getMyCourses() {
    return axios.get(`${SERVER_HOST}/me/courses`, generateAuthHeaders());
  },
  enrollCourse(cid) {
    return axios.post(
      `${SERVER_HOST}/courses/${cid}/enroll`,
      null,
      generateAuthHeaders()
    );
  },
  unenrollCourse(cid) {
    return axios.delete(
      `${SERVER_HOST}/courses/${cid}/enroll`,
      generateAuthHeaders()
    );
  },
  //not used
  // enrollUserToCourse(cid, students) {
  //   return axios.post(
  //     `${SERVER_HOST}/courses/${cid}/enroll`,
  //     { students },
  //     generateAuthHeaders()
  //   );
  // },
  unenrollUserFromCourse(cid, students) {
    return axios.delete(`${SERVER_HOST}/courses/${cid}/students`, {
      ...generateAuthHeaders(),
      data: { students },
    });
  },
  uploadFilesToExercise(eid, files, progress) {
    return axios.post(`${SERVER_HOST}/exercises/${eid}/files`, files, {
      ...generateAuthHeaders(),
      onUploadProgress: progress,
    });
  },
  uploadTestscriptsToExercise(eid, files, progress) {
    return axios.post(`${SERVER_HOST}/exercises/${eid}/testscripts`, files, {
      ...generateAuthHeaders(),
      onUploadProgress: progress,
    });
  },
  deleteFilesOfExercise(eid, files) {
    return axios.delete(`${SERVER_HOST}/exercises/${eid}/files`, {
      ...generateAuthHeaders(),
      data: files,
    });
  },
  deleteTestscriptsOfExercise(eid, testscripts) {
    return axios.delete(`${SERVER_HOST}/exercises/${eid}/testscripts`, {
      ...generateAuthHeaders(),
      data: testscripts,
    });
  },
  downloadTestscripts(eid, testscript) {
    axios({
      url: `${SERVER_HOST}/exercises/${eid}/testscripts/${testscript}`,
      method: "GET",
      responseType: "blob",
      ...generateAuthHeaders(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", testscript);
      document.body.appendChild(link);
      link.click();
    });
  },
  downloadSubmissionFile(sid, filename) {
    axios({
      url: `${SERVER_HOST}/submissions/${sid}/file`,
      method: "GET",
      responseType: "blob",
      ...generateAuthHeaders(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  },
  getTestsForExercise(eid) {
    return axios.get(
      `${SERVER_HOST}/exercises/${eid}/tests`,
      generateAuthHeaders()
    );
  },
  addTest(test) {
    delete test._id;
    const { exercise_id: eid } = test;
    return axios.post(
      `${SERVER_HOST}/exercises/${eid}/tests`,
      test,
      generateAuthHeaders()
    );
  },
  deleteTest(tid) {
    return axios.delete(`${SERVER_HOST}/tests/${tid}`, generateAuthHeaders());
  },
  updateTest(test) {
    const { _id: tid } = test;
    return axios.patch(
      `${SERVER_HOST}/tests/${tid}`,
      test,
      generateAuthHeaders()
    );
  },
  getMySubmissions({
    skip = 0,
    limit = 10,
    sort_column,
    sort_order,
    course_id = "",
    exercise_id = "",
  }) {
    return axios.get(
      `${SERVER_HOST}/me/submissions?skip=${skip}&limit=${limit}&sort_column=${sort_column}&sort_order=${sort_order}&course_id=${course_id}&exercise_id=${exercise_id}`,
      generateAuthHeaders()
    );
  },
  getSubmissions({
    skip = 0,
    limit = 10,
    sort_column,
    sort_order,
    course_id = "",
    exercise_id = "",
    user_id = "",
  }) {
    return axios.get(
      `${SERVER_HOST}/submissions?skip=${skip}&limit=${limit}&sort_column=${sort_column}&sort_order=${sort_order}&course_id=${course_id}&exercise_id=${exercise_id}&user_id=${user_id}`,
      generateAuthHeaders()
    );
  },
  deleteSubmission(sid) {
    return axios.delete(
      `${SERVER_HOST}/submissions/${sid}`,
      generateAuthHeaders()
    );
  },
  clearstatusSubmission(sid) {
    return axios.post(
      `${SERVER_HOST}/submissions/${sid}/clearstatus`,
      {},
      generateAuthHeaders()
    );
  },
  getAllUsers({ skip = 0, limit = 10, sort_column, sort_order }) {
    return axios.get(
      `${SERVER_HOST}/users?skip=${skip}&limit=${limit}&sort_column=${sort_column}&sort_order=${sort_order}`,
      generateAuthHeaders()
    );
  },
  deleteUser(uid) {
    return axios.delete(`${SERVER_HOST}/users/${uid}`, generateAuthHeaders());
  },
  updateUser(user) {
    const { _id: uid } = user;
    return axios.patch(
      `${SERVER_HOST}/users/${uid}`,
      user,
      generateAuthHeaders()
    );
  },
  addUser(user) {
    delete user._id;
    return axios.post(`${SERVER_HOST}/users`, user, generateAuthHeaders());
  },
  getUser(uid) {
    return axios.get(`${SERVER_HOST}/users/${uid}`, generateAuthHeaders());
  },
  uploadUsersCSV(file, progress) {
    return axios.post(`${SERVER_HOST}/users`, file, {
      ...generateAuthHeaders(),
      onUploadProgress: progress,
    });
  },
  enrollUserToCourseCSV(cid, file, progress) {
    return axios.post(`${SERVER_HOST}/courses/${cid}/students`, file, {
      ...generateAuthHeaders(),
      onUploadProgress: progress,
    });
  },
  makeExerciseSubmission(eid, file, progress) {
    return axios.post(`${SERVER_HOST}/exercises/${eid}/submissions`, file, {
      ...generateAuthHeaders(),
      onUploadProgress: progress,
    });
  },
  getExerciseSubmissionCount(eid) {
    return axios.get(
      `${SERVER_HOST}/exercises/${eid}/submissions/count`,
      generateAuthHeaders()
    );
  },
  getCurrentUser() {
    return axios.get(`${SERVER_HOST}/me`, generateAuthHeaders());
  },
  updateCurrentUser(user) {
    return axios.patch(`${SERVER_HOST}/me`, user, generateAuthHeaders());
  },
  generatePasswordForUser(uid) {
    return axios.post(
      `${SERVER_HOST}/users/${uid}/password`,
      {},
      generateAuthHeaders()
    );
  },
  getCourseReportNosubmission(cid) {
    return axios.get(
      `${SERVER_HOST}/courses/${cid}/reports/nosubmission`,
      generateAuthHeaders()
    );
  },
  getCourseReport(cid) {
    return axios.get(
      `${SERVER_HOST}/courses/${cid}/reports`,
      generateAuthHeaders()
    );
  },
  getExercisesReportNosubmission(ids) {
    return axios.post(
      `${SERVER_HOST}/exercises/reports`,
      { ids },
      generateAuthHeaders()
    );
  },
};
export default Api;
