import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CSubheader,
  CBreadcrumbRouter,
  CButton,
  CAlert,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import logo from "../logo_purple.png";
import logoSymbol from "../logo_symbol_purple.png";

import { toggle } from "../redux/sidebar/sidebarSlice";
import { logout } from "../redux/login/loginSlice";

// routes config
import routes from "../routes";
import { Link, withRouter } from "react-router-dom";

const TheHeader = (props) => {
  const dispatch = useDispatch();
  const { show } = useSelector((state) => state.sidebar);
  const { user } = useSelector((state) => state.login);

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(show) ? false : "responsive";
    dispatch(toggle(val));
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(show) ? true : "responsive";
    dispatch(toggle(val));
  };

  return (
    <>
      <CHeader withSubheader>
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none"
          onClick={toggleSidebarMobile}
        />
        <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={toggleSidebar}
        />
        <CHeaderBrand className="mx-auto d-none d-sm-flex d-lg-none" to="/">
          <CIcon className="c-sidebar-brand-full" src={logo} height={35} />
        </CHeaderBrand>

        <CHeaderBrand className="mx-auto d-lg-none d-md-none d-sm-none" to="/">
          <CIcon
            className="c-sidebar-brand-full"
            src={logoSymbol}
            height={35}
          />
        </CHeaderBrand>

        <CHeaderNav className="mr-auto d-none d-sm-flex"></CHeaderNav>

        <CHeaderNav className="px-3">
          <CButton
            block
            color="primary"
            onClick={() => {
              dispatch(logout());
            }}
          >
            Logout
          </CButton>
        </CHeaderNav>

        <CSubheader className="px-3 justify-content-between">
          <CBreadcrumbRouter
            className="border-0 c-subheader-nav m-0 px-0 px-md-3"
            routes={routes}
          />
          <div className="mfe-2 c-subheader-nav">
            <Link className="c-subheader-nav-link" to="/profile">
              <CIcon name="cil-user" alt="Settings" />
              &nbsp;Edit Profile
            </Link>
          </div>
        </CSubheader>
      </CHeader>
      {user && user.prompt_change_password && (
        <CAlert color="danger" closeButton>
          You have not changed your generated password.{" "}
          <Link className="alert-link" to="/profile">
            Consider changing your password
          </Link>
        </CAlert>
      )}
    </>
  );
};

export default withRouter(TheHeader);
