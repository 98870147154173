//this will check for whether the user is logged in

export const ACCESS_TOKEN_KEY = "accessToken";
const AuthService = {
  isLoggedIn() {
    //check local storage
    if (!localStorage.getItem(ACCESS_TOKEN_KEY)) {
      return false;
    } else {
      return true;
    }
  },
  //get the uid of the current logged in user
  getUid() {
    return parseInt(localStorage.getItem("uid"));
  },
  login({ accessToken, role, _id: uid }) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

    if (role) {
      localStorage.setItem("role", role);
    }

    localStorage.setItem("uid", uid);
  },
  logout() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem("role");
    localStorage.removeItem("uid");
  },
  isAdmin() {
    if (
      !localStorage.getItem(ACCESS_TOKEN_KEY) ||
      !localStorage.getItem("role")
    ) {
      return false;
    } else {
      if (localStorage.getItem("role") === "admin") {
        return true;
      }
    }
  },
  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  },
};

// export default auth;
export default AuthService;
