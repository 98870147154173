import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login";

import "./scss/style.scss";

import TheLayout from "./containers/TheLayout";
import ResetPassword from "./components/ResetPassword";
import SetPassword from "./components/SetPassword";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" name="Login" component={Login} />
        <Route
          path="/resetpassword"
          name="Reset Password"
          component={ResetPassword}
        />
        <Route
          path="/setpassword"
          name="Set Password"
          component={SetPassword}
        />
        <PrivateRoute path="/" name="Home" component={TheLayout} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
