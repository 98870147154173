import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../helpers/Api";
import { handleReduxErrorCase } from "../../helpers/Misc";
import {
  enrollUsersToCourseAsync,
  getEnrollmentForCourseAsync,
} from "../courses/coursesSlice";
import { addTestAsync } from "../tests/testsSlice";

export const getAllUsersAsync = createAsyncThunk(
  "users/getAllUsers",
  //arg is not used
  async (filters, thunkAPI) => {
    try {
      const { data } = await Api.getAllUsers(filters);
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const getUserAsync = createAsyncThunk(
  "users/getUserAsync",
  //arg is not used
  async (uid, thunkAPI) => {
    try {
      const { data } = await Api.getUser(uid);
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  "users/deleteCourse",
  async ({ uid, successCb }, thunkAPI) => {
    try {
      await Api.deleteUser(uid);

      if (successCb) successCb();
      return uid;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "users/updateUser",
  async ({ user, successCb }, thunkAPI) => {
    try {
      await Api.updateUser(user);

      if (successCb) successCb();

      return user;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const addUserAsync = createAsyncThunk(
  "users/addUser",
  async ({ user, successCb }, thunkAPI) => {
    try {
      const { data } = await Api.addUser(user);

      if (successCb) successCb();
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const uploadUsersCSVAsync = createAsyncThunk(
  "users/uploadFilesToExercise",
  async ({ file, progress, successCb, failedCb }, thunkAPI) => {
    try {
      const { data } = await Api.uploadUsersCSV(file, progress);
      if (successCb) successCb();
      return data;
    } catch (e) {
      if (failedCb) failedCb();
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  //take note that currentUsers is an array (by index)
  //whereas users is an map (uid => user)
  initialState: { users: {}, currentUsers: [], total: 0, loading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsersAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const res = action.payload;
          state.total = res.total;
          const currentUsers = res.data;
          state.currentUsers = currentUsers;

          //update to the mapping of users
          for (const u of currentUsers) {
            state.users[u._id] = u;
          }
        }
        state.loading = false;
      })
      .addCase(addTestAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const user = action.payload;
          state.total = state.total + 1;
          state.users[user._id] = user;
        }
        state.loading = false;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const user = action.payload;
          const { _id: uid } = user;

          for (let i = 0; i < state.currentUsers.length; i++) {
            const u = state.currentUsers[i];

            if (u._id === uid) {
              const updatedUser = Object.assign({}, u, user);
              state.currentUsers[i] = updatedUser;
              break;
            }
          }
        }
        state.loading = false;
      })
      .addCase(getUserAsync.pending, (state, action) => {
        const uid = action.meta.arg;
        state.users[uid] = { loading: true };
      })
      .addCase(getUserAsync.rejected, (state, action) => {
        if (action.payload) {
          const user = action.payload;

          delete state.users[user._id];
        }
        state.loading = false;
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const user = action.payload;

          state.users[user._id] = user;
          for (const u of state.currentUsers) {
            if (u._id === user._id) {
              u.name = user.name;
              u.email = user.email;
              break;
            }
          }
        }
        state.loading = false;
      })
      .addCase(getEnrollmentForCourseAsync.fulfilled, (state, action) => {
        const users = action.payload;
        if (users) {
          for (const user of users) {
            state.users[user._id] = user;
          }
        }
        state.loading = false;
      })
      .addCase(enrollUsersToCourseAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const students = action.payload;

          for (const student of students) {
            state.users[student._id] = student;
          }
        }
      });
  },
});

export default usersSlice.reducer;
