import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: "responsive",
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: initialState,
  reducers: {
    toggle: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { toggle } = sidebarSlice.actions;
export default sidebarSlice.reducer;
