import React from "react";
import ReactDOM from "react-dom";
import "core-js";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import {
  cilPeople,
  cilPencil,
  cilCloudUpload,
  cilUser,
  cilLockLocked,
  cilSchool,
  cilCode,
  cilGauge,
  cilGraph,
  cilChevronBottom,
  cilChevronTop,
  cilFile,
  cilXCircle,
} from "@coreui/icons";

const icons = {
  cilPencil,
  cilPeople,
  cilCloudUpload,
  cilUser,
  cilLockLocked,
  cilSchool,
  cilCode,
  cilGauge,
  cilGraph,
  cilChevronBottom,
  cilChevronTop,
  cilFile,
  cilXCircle,
};

React.icons = icons;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
