import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../helpers/Api";
import AuthService from "../../helpers/AuthService";
import { handleReduxErrorCase } from "../../helpers/Misc";

export const getCurrentUserAsync = createAsyncThunk(
  "login/getCurrentUser",
  //arg is not used
  async (arg, thunkAPI) => {
    try {
      const { data } = await Api.getCurrentUser();
      return data;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const updateCurrentUserAsync = createAsyncThunk(
  "login/updateCurrentUser",
  async ({ user, successCb }, thunkAPI) => {
    try {
      await Api.updateCurrentUser(user);

      if (successCb) successCb();
      return user;
    } catch (e) {
      return handleReduxErrorCase(e, thunkAPI);
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoggedIn: AuthService.isLoggedIn(),
    user: null,
  },
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      if (action.payload) {
        state.user = action.payload;
      }
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      AuthService.logout();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUserAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.user = action.payload;
        }
      })
      .addCase(updateCurrentUserAsync.fulfilled, (state, action) => {
        const updatedUser = action.payload;
        if (updatedUser) {
          if (!updatedUser.password) {
            console.log("###in here!!!");
            const updatedUser = Object.assign({}, state.user, action.payload);
            state.user = updatedUser;
          }
        }
      });
  },
});

export const { login, logout } = loginSlice.actions;
export default loginSlice.reducer;
